//login constants
const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST"
const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS"
const USER_LOGIN_FAIL = "USER_LOGIN_FAIL"

//facebook
const USER_LOGIN_FACEBOOK_REQUEST = "USER_LOGIN_FACEBOOK_REQUEST"
const USER_LOGIN_FACEBOOK_SUCCESS = "USER_LOGIN_FACEBOOK_SUCCESS"
const USER_LOGIN_FACEBOOK_FAIL = "USER_LOGIN_FACEBOOK_FAIL"

//register constants
const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST"
const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS"
const USER_REGISTER_FAIL = "USER_REGISTER_FAIL"

//logout cosntants
const USER_LOGOUT_REQUEST = "USER_LOGOUT_REQUEST"
const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS"
const USER_LOGOUT_FAIL = "USER_LOGOUT_FAIL"


//change password
const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL"

//change password
const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST"
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS"
const RESET_PASSWORD_FAIL    = "RESET_PASSWORD_FAIL"

//change password email
const RESET_PASSWORD_EMAIL_REQUEST = "RESET_PASSWORD_EMAIL_REQUEST"
const RESET_PASSWORD_EMAIL_SUCCESS = "RESET_PASSWORD_EMAIL_SUCCESS"
const RESET_PASSWORD_EMAIL_FAIL    = "RESET_PASSWORD_EMAIL_FAIL"

//DELETE diplomat
const DELETE_DIPLOMAT_REQUEST = "DELETE_DIPLOMAT_REQUEST"
const DELETE_DIPLOMAT_SUCCESS = "DELETE_DIPLOMAT_SUCCESS"
const DELETE_DIPLOMAT_FAIL    = "DELETE_DIPLOMAT_FAIL"

//add diplomat
const ADD_DIPLOMAT_REQUEST = "ADD_DIPLOMAT_REQUEST"
const ADD_DIPLOMAT_SUCCESS = "ADD_DIPLOMAT_SUCCESS"
const ADD_DIPLOMAT_FAIL = "ADD_DIPLOMAT_FAIL"

//add documents
const ADD_DOCUMENTS_REQUEST = "ADD_DOCUMENTS_REQUEST"
const ADD_DOCUMENTS_SUCCESS = "ADD_DOCUMENTS_SUCCESS"
const ADD_DOCUMENTS_FAIL = "ADD_DOCUMENTS_FAIL"

//get documents
const GET_DOCUMENTS_REQUEST = "GET_DOCUMENTS_REQUEST"
const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS"
const GET_DOCUMENTS_FAIL = "GET_DOCUMENTS_FAIL"

//set personal info
const SET_PERSONAL_DATA_REQUEST = "SET_PERSONAL_DATA_REQUEST"
const SET_PERSONAL_DATA_SUCCESS = "SET_PERSONAL_DATA_SUCCESS"
const SET_PERSONAL_DATA_FAIL = "SET_PERSONAL_DATA_FAIL"

//get personal info
const GET_PERSONAL_DATA_REQUEST = "GET_PERSONAL_DATA_REQUEST"
const GET_PERSONAL_DATA_SUCCESS = "GET_PERSONAL_DATA_SUCCESS"
const GET_PERSONAL_DATA_FAIL = "GET_PERSONAL_DATA_FAIL"

//get company data
const GET_COMPANY_DATA_REQUEST = "GET_COMPANY_DATA_REQUEST"
const GET_COMPANY_DATA_SUCCESS = "GET_COMPANY_DATA_SUCCESS"
const GET_COMPANY_DATA_FAIL = "GET_COMPANY_DATA_FAIL"

//set company data
const SET_COMPANY_DATA_REQUEST = "SET_COMPANY_DATA_REQUEST"
const SET_COMPANY_DATA_SUCCESS = "SET_COMPANY_DATA_SUCCESS"
const SET_COMPANY_DATA_FAIL = "SET_COMPANY_DATA_FAIL"


//get competencies all
const GET_COMPETENCY_REQUEST = "GET_COMPETENCY_REQUEST"
const GET_COMPETENCY_SUCCESS = "GET_COMPETENCY_SUCCESS"
const GET_COMPETENCY_FAIL = "GET_COMPETENCY_FAIL"

//get user competency 
const GET_USER_COMPETENCY_REQUEST = "GET_USER_COMPETENCY_REQUEST"
const GET_USER_COMPETENCY_SUCCESS = "GET_USER_COMPETENCY_SUCCESS"
const GET_USER_COMPETENCY_FAIL = "GET_USER_COMPETENCY_FAIL"

//get competency
const SET_COMPETENCY_REQUEST = "SET_COMPETENCY_REQUEST"
const SET_COMPETENCY_SUCCESS = "SET_COMPETENCY_SUCCESS"
const SET_COMPETENCY_FAIL = "SET_COMPETENCY_FAIL"

//remove competency
const REMOVE_COMPETENCY_REQUEST = "REMOVE_COMPETENCY_REQUEST"
const REMOVE_COMPETENCY_SUCCESS = "REMOVE_COMPETENCY_SUCCESS"
const REMOVE_COMPETENCY_FAIL = "REMOVE_COMPETENCY_FAIL"

//get notifications
const GET_NOTIFICATION_REQUEST = "GET_NOTIFICATION_REQUEST"
const GET_NOTIFICATION_SUCCESS = "GET_NOTIFICATION_SUCCESS"
const GET_NOTIFICATION_FAIL = "GET_NOTIFICATION_FAIL"


//set notifications
const SET_NOTIFICATION_REQUEST = "SET_NOTIFICATION_REQUEST"
const SET_NOTIFICATION_SUCCESS = "SET_NOTIFICATION_SUCCESS"
const SET_NOTIFICATION_FAIL = "SET_NOTIFICATION_FAIL"

//get notifications
const GET_PROFILE_RATINGS_REQUEST = "GET_PROFILE_RATINGS_REQUEST"
const GET_PROFILE_RATINGS_SUCCESS = "GET_PROFILE_RATINGS_SUCCESS"
const GET_PROFILE_RATINGS_FAIL = "GET_PROFILE_RATINGS_FAIL"


//get work_experience
const GET_WORK_EXPERIENCE_REQUEST = "GET_WORK_EXPERIENCE_REQUEST"
const GET_WORK_EXPERIENCE_SUCCESS = "GET_WORK_EXPERIENCE_SUCCESS"
const GET_WORK_EXPERIENCE_FAIL = "GET_WORK_EXPERIENCE_FAIL"

//add work_experience
const ADD_WORK_EXPERIENCE_REQUEST = "ADD_WORK_EXPERIENCE_REQUEST"
const ADD_WORK_EXPERIENCE_SUCCESS = "ADD_WORK_EXPERIENCE_SUCCESS"
const ADD_WORK_EXPERIENCE_FAIL = "ADD_WORK_EXPERIENCE_FAIL"

//remove work_experience 
const DELETE_WORK_EXPERIENCE_REQUEST = "DELETE_WORK_EXPERIENCE_REQUEST"
const DELETE_WORK_EXPERIENCE_SUCCESS = "DELETE_WORK_EXPERIENCE_SUCCESS"
const DELETE_WORK_EXPERIENCE_FAIL = "DELETE_WORK_EXPERIENCE_FAIL"

//get availability
const GET_AVAILABILITY_REQUEST = "GET_AVAILABILITY_REQUEST"
const GET_AVAILABILITY_SUCCESS = "GET_AVAILABILITY_SUCCESS"
const GET_AVAILABILITY_FAIL = "GET_AVAILABILITY_FAIL"

//add availability
const ADD_AVAILABILITY_REQUEST = "ADD_AVAILABILITY_REQUEST"
const ADD_AVAILABILITY_SUCCESS = "ADD_AVAILABILITY_SUCCESS"
const ADD_AVAILABILITY_FAIL = "ADD_AVAILABILITY_FAIL"

//remove availability
const REMOVE_AVAILABILITY_REQUEST = "REMOVE_AVAILABILITY_REQUEST"
const REMOVE_AVAILABILITY_SUCCESS = "REMOVE_AVAILABILITY_SUCCESS"
const REMOVE_AVAILABILITY_FAIL = "REMOVE_AVAILABILITY_FAIL"

//get invited people
const GET_INVITED_REQUEST = "GET_INVITED_REQUEST"
const GET_INVITED_SUCCESS = "GET_INVITED_SUCCESS"
const GET_INVITED_FAIL = "GET_INVITED_FAIL"

//invite 
const INVITE_REQUEST = "INVITE_REQUEST"
const INVITE_SUCCESS = "INVITE_SUCCESS"
const INVITE_FAIL = "INVITE_FAIL"

//add contract
const ADD_CONTRACT_REQUEST = "ADD_CONTRACT_REQUEST";
const ADD_CONTRACT_SUCCESS = "ADD_CONTRACT_SUCCESS";
const ADD_CONTRACT_FAIL = "ADD_CONTRACT_FAIL";

//get contract
const GET_CONTRACT_REQUEST = "GET_CONTRACT_REQUEST";
const GET_CONTRACT_SUCCESS = "GET_CONTRACT_SUCCESS";
const GET_CONTRACT_FAIL = "GET_CONTRACT_FAIL";

//get organasations
const GET_ORGANASATIONS_REQUEST = "GET_ORGANASATIONS_REQUEST";
const GET_ORGANASATIONS_SUCCESS = "GET_ORGANASATIONS_SUCCESS";
const GET_ORGANASATIONS_FAIL = "GET_ORGANASATIONS_FAIL";

//set organasations
const SET_ORGANASATIONS_REQUEST = "SET_ORGANASATIONS_REQUEST";
const SET_ORGANASATIONS_SUCCESS = "SET_ORGANASATIONS_SUCCESS";
const SET_ORGANASATIONS_FAIL    = "SET_ORGANASATIONS_FAIL";

//get timecard 
const GET_TIMECARD_REQUEST = "GET_TIMECARD_REQUEST";
const GET_TIMECARD_SUCCESS = "GET_TIMECARD_SUCCESS";
const GET_TIMECARD_FAIL    = "GET_TIMECARD_FAIL";

//set timecard 
const SET_TIMECARD_REQUEST = "SET_TIMECARD_REQUEST";
const SET_TIMECARD_SUCCESS = "SET_TIMECARD_SUCCESS";
const SET_TIMECARD_FAIL = "SET_TIMECARD_FAIL";

//delete timecard file 
const DELETE_TIMECARD_FILE_REQUEST = "DELETE_TIMECARD_FILE_REQUEST";
const DELETE_TIMECARD_FILE_SUCCESS =  "DELETE_TIMECARD_FILE_SUCCCESS";
const DELETE_TIMECARD_FILE_FAIL = "DELETE_TIMECARD_FILE_FAIL";

//get client orga info
const GET_CLT_ORGA_REQUEST = "GET_CLT_ORGA_REQUEST";
const GET_CLT_ORGA_SUCCESS = "GET_CLT_ORGA_SUCCESS";
const GET_CLT_ORGA_FAIL = "GET_CLT_ORGA_FAIL";

//set client orga info
const SET_CLT_ORGA_REQUEST = "SET_CLT_ORGA_REQUEST";
const SET_CLT_ORGA_SUCCESS = "SET_CLT_ORGA_SUCCESS";
const SET_CLT_ORGA_FAIL    = "SET_CLT_ORGA_FAIL";

//get client orga info
const GET_LOCATION_REQUEST = "GET_LOCATION_REQUEST";
const GET_LOCATION_SUCCESS = "GET_LOCATION_SUCCESS";
const GET_LOCATION_FAIL    = "GET_LOCATION_FAIL";

//delete client orga info
const DELETE_LOCATION_REQUEST = "DELETE_LOCATION_REQUEST";
const DELETE_LOCATION_SUCCESS = "DELETE_LOCATION_SUCCESS";
const DELETE_LOCATION_FAIL    = "DELETE_LOCATION_FAIL";

//set client orga info
const SET_LOCATION_REQUEST = "SET_LOCATION_REQUEST";
const SET_LOCATION_SUCCESS = "SET_LOCATION_SUCCESS";
const SET_LOCATION_FAIL    = "SET_LOCATION_FAIL";

//set client orga info
const ADD_LOCATION_REQUEST = "ADD_LOCATION_REQUEST";
const ADD_LOCATION_SUCCESS = "ADD_LOCATION_SUCCESS";
const ADD_LOCATION_FAIL    = "ADD_LOCATION_FAIL";

//get additional users 
const GET_ADDITIONAL_USRES_REQUEST = "GET_ADDITIONAL_USRES_REQUEST";
const GET_ADDITIONAL_USRES_SUCCESS = "GET_ADDITIONAL_USRES_SUCCESS";
const GET_ADDITIONAL_USRES_FAIL    = "GET_ADDITIONAL_USRES_FAIL";

//set additional users 
const SET_ADDITIONAL_USERS_REQUEST = "SET_ADDITIONAL_USERS_REQUEST";
const SET_ADDITIONAL_USERS_SUCCESS = "SET_ADDITIONAL_USERS_SUCCESS";
const SET_ADDITIONAL_USERS_FAIL    = "SET_ADDITIONAL_USERS_FAIL";

//add additional users
const ADD_ADDITIONAL_USERS_REQUEST = "ADD_ADDITIONAL_USERS_REQUEST";
const ADD_ADDITIONAL_USERS_SUCCESS = "ADD_ADDITIONAL_USERS_SUCCESS";
const ADD_ADDITIONAL_USERS_FAIL    = "ADD_ADDITIONAL_USERS_FAIL";

//get review
const GET_REVIEW_REQUEST = "GET_REVIEW_REQUEST";
const GET_REVIEW_SUCCESS = "GET_REVIEW_SUCCESS";
const GET_REVIEW_FAIL    = "GET_REVIEW_FAIL";

//add review
const ADD_REVIEW_REQUEST = "ADD_REVIEW_REQUEST";
const ADD_REVIEW_SUCCESS = "ADD_REVIEW_SUCCESS";
const ADD_REVIEW_FAIL    = "ADD_REVIEW_FAIL";

//remove review
const DELETE_REVIEW_REQUEST = "DELETE_REVIEW_REQUEST";
const DELETE_REVIEW_SUCCESS = "DELETE_REVIEW_SUCCESS";
const DELETE_REVIEW_FAIL    = "DELETE_REVIEW_FAIL";

//add assignement
const ADD_ASSIGNEMENT_REQUEST = "ADD_ASSIGNEMENT_REQUEST";
const ADD_ASSIGNEMENT_SUCCESS = "ADD_ASSIGNEMENT_SUCCESS";
const ADD_ASSIGNEMENT_FAIL    = "ADD_ASSIGNEMENT_FAIL";

//get assignement
const GET_ASSIGNEMENT_REQUEST = "GET_ASSIGNEMENT_REQUEST";
const GET_ASSIGNEMENT_SUCCESS = "GET_ASSIGNEMENT_SUCCESS";
const GET_ASSIGNEMENT_FAIL    = "GET_ASSIGNEMENT_FAIL";

//get all assignement
const GET_ALL_ASSIGNEMENT_REQUEST = "GET_ALL_ASSIGNEMENT_REQUEST";
const GET_ALL_ASSIGNEMENT_SUCCESS = "GET_ALL_ASSIGNEMENT_SUCCESS";
const GET_ALL_ASSIGNEMENT_FAIL    = "GET_ALL_ASSIGNEMENT_FAIL";

//set assignement's status
const SET_ASSIGNEMENT_REQUEST = "SET_ASSIGNEMENT_REQUEST";
const SET_ASSIGNEMENT_SUCCESS = "SET_ASSIGNEMENT_SUCCESS";
const SET_ASSIGNEMENT_FAIL    = "SET_ASSIGNEMENT_FAIL";

//get geolocation
//get all assignement
const GET_GEOLOCATION_REQUEST = "GET_GEOLOCATION_REQUEST";
const GET_GEOLOCATION_SUCCESS = "GET_GEOLOCATION_SUCCESS";
const GET_GEOLOCATION_FAIL    = "GET_GEOLOCATION_FAIL";

//delete assignement
const DELETE_ASSIGNEMENT_REQUEST = "DELETE_ASSIGNEMENT_REQUEST";
const DELETE_ASSIGNEMENT_SUCCESS = "DELETE_ASSIGNEMENT_SUCCESS";
const DELETE_ASSIGNEMENT_FAIL    = "DELETE_ASSIGNEMENT_FAIL";

//update user image or video
const UPDATE_USER_FILE_REQUEST = "UPDATE_USER_FILE_REQUEST";
const UPDATE_USER_FILE_SUCCESS = "UPDATE_USER_FILE_SUCCESS";
const UPDATE_USER_FILE_FAIL    = "UPDATE_USER_FILE_FAIL";

//search
const SEARCH_REQUEST = "SEARCH_REQUEST"
const SEARCH_SUCCESS = "SEARCH_SUCCESS"
const SEARCH_FAIL    = "SEARCH_FAIL"

//like
const LIKE_REQUEST = "LIKE_REQUEST"
const LIKE_SUCCESS = "LIKE_SUCCESS"
const LIKE_FAIL    = "LIKE_FAIL"

//like
const GET_LIKED_REQUEST = "GET_LIKED_REQUEST"
const GET_LIKED_SUCCESS = "GET_LIKED_SUCCESS"
const GET_LIKED_FAIL    = "GET_LIKED_FAIL"

//remove like
const REMOVE_LIKE_REQUEST = "REMOVE_LIKE_REQUEST"
const REMOVE_LIKE_SUCCESS = "REMOVE_LIKE_SUCCESS"
const REMOVE_LIKE_FAIL    = "REMOVE_LIKE_FAIL"

//apply
const APPLY_REQUEST = "APPLY_REQUEST"
const APPLY_SUCCESS = "APPLY_SUCCESS"
const APPLY_FAIL    = "APPLY_FAIL"

//get applications
const GET_APPLICATION_REQUEST = "GET_APPLICATION_REQUEST"
const GET_APPLICATION_SUCCESS = "GET_APPLICATION_SUCCESS"
const GET_APPLICATION_FAIL    = "GET_APPLICATION_FAIL"

//delete applications
const DELETE_APPLICATION_REQUEST = "REMOVE_APPLICATION_REQUEST"
const DELETE_APPLICATION_SUCCESS = "REMOVE_APPLICATION_SUCCESS"
const DELETE_APPLICATION_FAIL    = "REMOVE_APPLICATION_FAIL"

//Invite freelancer
const INVITE_FREELANCER_REQUEST = "INVITE_FREELANCER_REQUEST"
const INVITE_FREELANCER_SUCCESS = "INVITE_FREELANCER_SUCCESS"
const INVITE_FREELANCER_FAIL    = "INVITE_FREELANCER_FAIL"

// get Invited freelancer
const GET_INVITED_FREELANCER_REQUEST = "GET_INVITED_FREELANCER_REQUEST"
const GET_INVITED_FREELANCER_SUCCESS = "GET_INVITED_FREELANCER_SUCCESS"
const GET_INVITED_FREELANCER_FAIL    = "GET_INVITED_FREELANCER_FAIL"

// deleted Invited freelancer
const DELETE_INVITED_FREELANCER_REQUEST = "DELETE_INVITED_FREELANCER_REQUEST"
const DELETE_INVITED_FREELANCER_SUCCESS = "DELETE_INVITED_FREELANCER_SUCCESS"
const DELETE_INVITED_FREELANCER_FAIL    = "DELETE_INVITED_FREELANCER_FAIL"

// add assignement alert
const ADD_ASSIGNEMENT_ALERT_REQUEST = "ADD_ASSIGNEMENT_ALERT_REQUEST"
const ADD_ASSIGNEMENT_ALERT_SUCCESS = "ADD_ASSIGNEMENT_ALERT_SUCCESS"
const ADD_ASSIGNEMENT_ALERT_FAIL    = "ADD_ASSIGNEMENT_ALERT_FAIL"

//get assignement alerts 
const GET_ASSIGNEMENT_ALERT_REQUEST = "GET_ASSIGNEMENT_ALERT_REQUEST"
const GET_ASSIGNEMENT_ALERT_SUCCESS = "GET_ASSIGNEMENT_ALERT_SUCCESS"
const GET_ASSIGNEMENT_ALERT_FAIL    = "GET_ASSIGNEMENT_ALERT_FAIL"

// deleted assignement alert
const DELETE_ASSIGNEMENT_ALERT_REQUEST = "DELETE_ASSIGNEMENT_ALERT_REQUEST"
const DELETE_ASSIGNEMENT_ALERT_SUCCESS = "DELETE_ASSIGNEMENT_ALERT_SUCCESS"
const DELETE_ASSIGNEMENT_ALERT_FAIL    = "DELETE_ASSIGNEMENT_ALERT_FAIL"

//login management
const LOGIN_MANAGEMENT_REQUEST = "LOGIN_MANAGEMENT_REQUEST";
const LOGIN_MANAGEMENT_SUCCESS = "LOGIN_MANAGEMENT_SUCCESS";
const LOGIN_MANAGEMENT_FAIL    = "LOGIN_MANAGEMENT_FAIL"; 

//add invoice
const ADD_INVOICE_REQUEST = "ADD_INVOICE_REQUEST"
const ADD_INVOICE_SUCCESS = "ADD_INVOICE_SUCCESS"
const ADD_INVOICE_FAIL    = "ADD_INVOICE_FAIL"

//get invoice
const GET_INVOICE_REQUEST = "GET_INVOICE_REQUEST"
const GET_INVOICE_SUCCESS = "GET_INVOICE_SUCCESS"
const GET_INVOICE_FAIL    = "GET_INVOICE_FAIL"

//delete invoice file
const DELETE_INVOICE_REQUEST = "DELETE_INVOICE_REQUEST"
const DELETE_INVOICE_SUCCESS = "DELETE_INVOICE_SUCCESS"
const DELETE_INVOICE_FAIL    = "DELETE_INVOICE_FAIL"

//delete invoice file
const DELETE_INVOICE_FILE_REQUEST = "DELETE_INVOICE_FILE_REQUEST"
const DELETE_INVOICE_FILE_SUCCESS = "DELETE_INVOICE_FILE_SUCCESS"
const DELETE_INVOICE_FILE_FAIL    = "DELETE_INVOICE_FILE_FAIL"

//add favorite
const ADD_FAVORITE_REQUEST = "ADD_FAVORITE_REQUEST"
const ADD_FAVORITE_SUCCESS = "ADD_FAVORITE_SUCCESS"
const ADD_FAVORITE_FAIL    = "ADD_FAVORITE_FAIL"

//get favorite
const GET_FAVORITE_REQUEST = "GET_FAVORITE_REQUEST"
const GET_FAVORITE_SUCCESS = "GET_FAVORITE_SUCCESS"
const GET_FAVORITE_FAIL    = "GET_FAVORITE_FAIL"

//remove favorite
const REMOVE_FAVORITE_REQUEST = "REMOVE_FAVORITE_REQUEST"
const REMOVE_FAVORITE_SUCCESS = "REMOVE_FAVORITE_SUCCESS"
const REMOVE_FAVORITE_FAIL    = "REMOVE_FAVORITE_FAIL"


//add reserve service
const ADD_RESERVE_REQUEST = "ADD_RESERVE_REQUEST"
const ADD_RESERVE_SUCCESS = "ADD_RESERVE_SUCCESS"
const ADD_RESERVE_FAIL    = "ADD_RESERVE_FAIL"

//GET reserve service
const GET_RESERVE_REQUEST = "GET_RESERVE_REQUEST"
const GET_RESERVE_SUCCESS = "GET_RESERVE_SUCCESS"
const GET_RESERVE_FAIL    = "GET_RESERVE_FAIL"

//SET reserve service
const SET_RESERVE_REQUEST = "SET_RESERVE_REQUEST"
const SET_RESERVE_SUCCESS = "SET_RESERVE_SUCCESS"
const SET_RESERVE_FAIL    = "SET_RESERVE_FAIL"

//REMOVE reserve service
const REMOVE_RESERVE_REQUEST = "REMOVE_RESERVE_REQUEST"
const REMOVE_RESERVE_SUCCESS = "REMOVE_RESERVE_SUCCESS"
const REMOVE_RESERVE_FAIL    = "REMOVE_RESERVE_FAIL"

//send verification email
const VERIFY_EMAIL_REQUEST = "VERIFY_EMAIL_REQUEST"
const VERIFY_EMAIL_SUCCESS = "VERIFY_EMAIL_SUCCESS"
const VERIFY_EMAIL_FAIL    = "VERIFY_EMAIL_FAIL"

//send verification email
const CHECK_VERIFY_EMAIL_REQUEST = "CHECK_VERIFY_EMAIL_REQUEST"
const CHECK_VERIFY_EMAIL_SUCCESS = "CHECK_VERIFY_EMAIL_SUCCESS"
const CHECK_VERIFY_EMAIL_FAIL    = "CHECK_VERIFY_EMAIL_FAIL"

//get diplomat type 
const GET_DIPLOMAT_TYPES_REQUEST = "GET_DIPLOMAT_TYPES_REQUEST"
const GET_DIPLOMAT_TYPES_SUCCESS = "GET_DIPLOMAT_TYPES_SUCCESS"
const GET_DIPLOMAT_TYPES_FAIL    = "GET_DIPLOMAT_TYPES_FAIL"


//add diplomat type 
const ADD_DIPLOMAT_TYPE_REQUEST = "ADD_DIPLOMAT_TYPE_REQUEST"
const ADD_DIPLOMAT_TYPE_SUCCESS = "ADD_DIPLOMAT_TYPE_SUCCESS"
const ADD_DIPLOMAT_TYPE_FAIL    = "ADD_DIPLOMAT_TYPE_FAIL"

//add lang fav
const GET_LANG_REQUEST = "GET_LANG_REQUEST"
const GET_LANG_SUCCESS = "GET_LANG_SUCCESS"
const GET_LANG_FAIL    = "GET_LANG_FAIL"

//set lang fav
const SET_LANG_REQUEST = "SET_LANG_REQUEST"
const SET_LANG_SUCCESS = "SET_LANG_SUCCESS"
const SET_LANG_FAIL    = "SET_LANG_FAIL"


//add new industry
const ADD_INDUSTRY_REQUEST = "ADD_INDUSTRY_REQUEST"
const ADD_INDUSTRY_SUCCESS = "ADD_INDUSTRY_SUCCESS"
const ADD_INDUSTRY_FAIL    = "ADD_INDUSTRY_FAIL"

//delete  file 
const DELETE_FILE_REQUEST =  "DELETE_FILE_REQUEST";
const DELETE_FILE_SUCCESS =  "DELETE_FILE_SUCCCESS";
const DELETE_FILE_FAIL    =  "DELETE_FILE_FAIL";


//logout
const LOGOUT_REQUEST = "LOGOUT_REQUEST"
const LOGOUT_SUCCESS = "LOGOUT_SUCCESS"
const LOGOUT_FAIL = "LOGOUT_FAIL"

//CONTACT US EMAIL 
const SEND_EMAIL_CONTACT_REQUEST =  "SEND_EMAIL_CONTACT_REQUEST";
const SEND_EMAIL_CONTACT_SUCCESS =  "SEND_EMAIL_CONTACT_SUCCCESS";
const SEND_EMAIL_CONTACT_FAIL    =  "SEND_EMAIL_CONTACT_FAIL";

//delete account 
const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST"
const DELETE_ACCOUNT_SUCCESS = "DELETE_ACCOUNT_SUCCESS"
const DELETE_ACCOUNT_FAIL = "DELETE_ACCOUNT_FAIL"

export {USER_LOGIN_REQUEST,USER_LOGIN_SUCCESS,USER_LOGIN_FAIL,USER_REGISTER_FAIL,USER_REGISTER_REQUEST,USER_REGISTER_SUCCESS,
CHANGE_PASSWORD_FAIL,CHANGE_PASSWORD_SUCCESS,CHANGE_PASSWORD_REQUEST,USER_LOGOUT_FAIL,USER_LOGOUT_SUCCESS,USER_LOGOUT_REQUEST,
ADD_DIPLOMAT_FAIL,ADD_DIPLOMAT_SUCCESS,ADD_DIPLOMAT_REQUEST, ADD_DOCUMENTS_REQUEST,ADD_DOCUMENTS_SUCCESS,ADD_DOCUMENTS_FAIL,
GET_DOCUMENTS_FAIL,GET_DOCUMENTS_REQUEST,GET_DOCUMENTS_SUCCESS,SET_PERSONAL_DATA_FAIL,SET_PERSONAL_DATA_REQUEST,SET_PERSONAL_DATA_SUCCESS,
GET_PERSONAL_DATA_REQUEST,GET_PERSONAL_DATA_SUCCESS,
GET_PERSONAL_DATA_FAIL,GET_COMPANY_DATA_REQUEST,GET_COMPANY_DATA_FAIL,GET_COMPANY_DATA_SUCCESS,SET_COMPANY_DATA_REQUEST,
SET_COMPANY_DATA_SUCCESS,SET_COMPANY_DATA_FAIL,GET_COMPETENCY_FAIL,GET_COMPETENCY_REQUEST,GET_COMPETENCY_SUCCESS,
SET_COMPETENCY_REQUEST,SET_COMPETENCY_SUCCESS,SET_COMPETENCY_FAIL,REMOVE_COMPETENCY_FAIL,REMOVE_COMPETENCY_SUCCESS,
REMOVE_COMPETENCY_REQUEST,GET_USER_COMPETENCY_FAIL,GET_USER_COMPETENCY_REQUEST,GET_USER_COMPETENCY_SUCCESS,
GET_NOTIFICATION_FAIL,GET_NOTIFICATION_REQUEST,GET_NOTIFICATION_SUCCESS,SET_NOTIFICATION_FAIL,SET_NOTIFICATION_SUCCESS,
SET_NOTIFICATION_REQUEST,GET_PROFILE_RATINGS_REQUEST,GET_PROFILE_RATINGS_SUCCESS,GET_PROFILE_RATINGS_FAIL,
GET_WORK_EXPERIENCE_FAIL,GET_WORK_EXPERIENCE_SUCCESS,GET_WORK_EXPERIENCE_REQUEST,ADD_WORK_EXPERIENCE_FAIL,ADD_WORK_EXPERIENCE_SUCCESS,
ADD_WORK_EXPERIENCE_REQUEST,DELETE_ACCOUNT_REQUEST,DELETE_ACCOUNT_SUCCESS,DELETE_ACCOUNT_FAIL,LOGOUT_FAIL,LOGOUT_SUCCESS,LOGOUT_REQUEST,
GET_AVAILABILITY_FAIL,GET_AVAILABILITY_REQUEST,GET_AVAILABILITY_SUCCESS,ADD_AVAILABILITY_FAIL,ADD_AVAILABILITY_REQUEST,ADD_AVAILABILITY_SUCCESS,
REMOVE_AVAILABILITY_FAIL,REMOVE_AVAILABILITY_REQUEST,REMOVE_AVAILABILITY_SUCCESS,GET_INVITED_REQUEST,GET_INVITED_FAIL,GET_INVITED_SUCCESS,
INVITE_FAIL,INVITE_SUCCESS,INVITE_REQUEST,DELETE_WORK_EXPERIENCE_FAIL,DELETE_WORK_EXPERIENCE_SUCCESS,DELETE_WORK_EXPERIENCE_REQUEST,
USER_LOGIN_FACEBOOK_FAIL,USER_LOGIN_FACEBOOK_REQUEST,USER_LOGIN_FACEBOOK_SUCCESS,ADD_CONTRACT_FAIL,ADD_CONTRACT_REQUEST,ADD_CONTRACT_SUCCESS,
GET_CONTRACT_FAIL,GET_CONTRACT_REQUEST,GET_CONTRACT_SUCCESS,GET_ORGANASATIONS_REQUEST,GET_ORGANASATIONS_SUCCESS,GET_ORGANASATIONS_FAIL,
SET_ORGANASATIONS_FAIL,SET_ORGANASATIONS_SUCCESS,SET_ORGANASATIONS_REQUEST,SET_TIMECARD_FAIL,SET_TIMECARD_REQUEST,SET_TIMECARD_SUCCESS,
GET_TIMECARD_FAIL,GET_TIMECARD_REQUEST,GET_TIMECARD_SUCCESS,DELETE_TIMECARD_FILE_FAIL,DELETE_TIMECARD_FILE_REQUEST,DELETE_TIMECARD_FILE_SUCCESS,
GET_CLT_ORGA_FAIL,GET_CLT_ORGA_REQUEST,GET_CLT_ORGA_SUCCESS,SET_CLT_ORGA_FAIL,SET_CLT_ORGA_REQUEST,SET_CLT_ORGA_SUCCESS,GET_LOCATION_FAIL,GET_LOCATION_REQUEST,GET_LOCATION_SUCCESS,
SET_LOCATION_FAIL,SET_LOCATION_REQUEST,SET_LOCATION_SUCCESS,ADD_LOCATION_FAIL,ADD_LOCATION_REQUEST,ADD_LOCATION_SUCCESS,ADD_ADDITIONAL_USERS_FAIL,ADD_ADDITIONAL_USERS_REQUEST,ADD_ADDITIONAL_USERS_SUCCESS,
GET_ADDITIONAL_USRES_FAIL,GET_ADDITIONAL_USRES_REQUEST,GET_ADDITIONAL_USRES_SUCCESS,SET_ADDITIONAL_USERS_FAIL,SET_ADDITIONAL_USERS_REQUEST,SET_ADDITIONAL_USERS_SUCCESS,ADD_ASSIGNEMENT_FAIL,ADD_ASSIGNEMENT_REQUEST,
ADD_ASSIGNEMENT_SUCCESS,GET_ASSIGNEMENT_FAIL,GET_ASSIGNEMENT_REQUEST,GET_ASSIGNEMENT_SUCCESS,DELETE_ASSIGNEMENT_FAIL,DELETE_ASSIGNEMENT_REQUEST,DELETE_ASSIGNEMENT_SUCCESS,GET_ALL_ASSIGNEMENT_FAIL,GET_ALL_ASSIGNEMENT_REQUEST,
GET_ALL_ASSIGNEMENT_SUCCESS,GET_REVIEW_FAIL,GET_REVIEW_REQUEST,GET_REVIEW_SUCCESS,ADD_REVIEW_FAIL,ADD_REVIEW_REQUEST,ADD_REVIEW_SUCCESS,UPDATE_USER_FILE_FAIL,UPDATE_USER_FILE_REQUEST,UPDATE_USER_FILE_SUCCESS,
SET_ASSIGNEMENT_FAIL,SET_ASSIGNEMENT_REQUEST,SET_ASSIGNEMENT_SUCCESS,DELETE_DIPLOMAT_FAIL,DELETE_DIPLOMAT_REQUEST,DELETE_DIPLOMAT_SUCCESS,DELETE_LOCATION_FAIL,DELETE_LOCATION_REQUEST,DELETE_LOCATION_SUCCESS,
RESET_PASSWORD_FAIL,RESET_PASSWORD_REQUEST,RESET_PASSWORD_SUCCESS,RESET_PASSWORD_EMAIL_FAIL,RESET_PASSWORD_EMAIL_REQUEST,RESET_PASSWORD_EMAIL_SUCCESS,DELETE_REVIEW_FAIL,DELETE_REVIEW_REQUEST,DELETE_REVIEW_SUCCESS,
GET_GEOLOCATION_FAIL,GET_GEOLOCATION_REQUEST,GET_GEOLOCATION_SUCCESS,SEARCH_FAIL,SEARCH_REQUEST,SEARCH_SUCCESS,LIKE_FAIL,LIKE_REQUEST,LIKE_SUCCESS,GET_LIKED_FAIL,GET_LIKED_REQUEST,GET_LIKED_SUCCESS,REMOVE_LIKE_FAIL,
REMOVE_LIKE_REQUEST,REMOVE_LIKE_SUCCESS,APPLY_FAIL,APPLY_REQUEST,APPLY_SUCCESS,GET_APPLICATION_FAIL,GET_APPLICATION_REQUEST,GET_APPLICATION_SUCCESS,INVITE_FREELANCER_FAIL,INVITE_FREELANCER_REQUEST,INVITE_FREELANCER_SUCCESS,
GET_INVITED_FREELANCER_FAIL,GET_INVITED_FREELANCER_REQUEST,GET_INVITED_FREELANCER_SUCCESS,DELETE_INVITED_FREELANCER_FAIL,DELETE_INVITED_FREELANCER_REQUEST,DELETE_INVITED_FREELANCER_SUCCESS,ADD_ASSIGNEMENT_ALERT_FAIL,ADD_ASSIGNEMENT_ALERT_REQUEST,
ADD_ASSIGNEMENT_ALERT_SUCCESS,GET_ASSIGNEMENT_ALERT_FAIL,GET_ASSIGNEMENT_ALERT_REQUEST,GET_ASSIGNEMENT_ALERT_SUCCESS,DELETE_ASSIGNEMENT_ALERT_FAIL,DELETE_ASSIGNEMENT_ALERT_REQUEST,DELETE_ASSIGNEMENT_ALERT_SUCCESS,LOGIN_MANAGEMENT_FAIL,LOGIN_MANAGEMENT_REQUEST,
LOGIN_MANAGEMENT_SUCCESS,ADD_INVOICE_FAIL,ADD_INVOICE_REQUEST,ADD_INVOICE_SUCCESS,GET_INVOICE_FAIL,GET_INVOICE_REQUEST,GET_INVOICE_SUCCESS,DELETE_INVOICE_FAIL,DELETE_INVOICE_REQUEST,DELETE_INVOICE_SUCCESS,DELETE_INVOICE_FILE_FAIL,DELETE_INVOICE_FILE_REQUEST,
DELETE_INVOICE_FILE_SUCCESS,ADD_FAVORITE_FAIL,ADD_FAVORITE_REQUEST,ADD_FAVORITE_SUCCESS,
GET_FAVORITE_FAIL,GET_FAVORITE_REQUEST,GET_FAVORITE_SUCCESS,REMOVE_FAVORITE_FAIL,REMOVE_FAVORITE_REQUEST,REMOVE_FAVORITE_SUCCESS,GET_RESERVE_REQUEST,GET_RESERVE_SUCCESS,GET_RESERVE_FAIL,SET_RESERVE_FAIL,SET_RESERVE_REQUEST,SET_RESERVE_SUCCESS,
ADD_RESERVE_FAIL,ADD_RESERVE_REQUEST,ADD_RESERVE_SUCCESS,REMOVE_RESERVE_FAIL,REMOVE_RESERVE_REQUEST,REMOVE_RESERVE_SUCCESS,VERIFY_EMAIL_FAIL,VERIFY_EMAIL_REQUEST,VERIFY_EMAIL_SUCCESS,CHECK_VERIFY_EMAIL_FAIL,CHECK_VERIFY_EMAIL_REQUEST,CHECK_VERIFY_EMAIL_SUCCESS,DELETE_APPLICATION_REQUEST,DELETE_APPLICATION_FAIL,DELETE_APPLICATION_SUCCESS
,DELETE_FILE_FAIL,DELETE_FILE_REQUEST,DELETE_FILE_SUCCESS,GET_DIPLOMAT_TYPES_FAIL,GET_DIPLOMAT_TYPES_REQUEST,GET_DIPLOMAT_TYPES_SUCCESS,ADD_DIPLOMAT_TYPE_FAIL,ADD_DIPLOMAT_TYPE_REQUEST,ADD_DIPLOMAT_TYPE_SUCCESS,SEND_EMAIL_CONTACT_FAIL,SEND_EMAIL_CONTACT_REQUEST,SEND_EMAIL_CONTACT_SUCCESS,
SET_LANG_FAIL,SET_LANG_REQUEST,SET_LANG_SUCCESS,GET_LANG_FAIL,GET_LANG_REQUEST,GET_LANG_SUCCESS,ADD_INDUSTRY_FAIL,ADD_INDUSTRY_REQUEST,ADD_INDUSTRY_SUCCESS};