import { applyMiddleware, combineReducers, compose, createStore } from "redux";
import thunk from "redux-thunk";
import {
  add_additional_user_reducer,
  add_assignement_alert_reducer,
  add_assignement_reducer,
  add_availability_reducer,
  add_competency_reducer,
  add_contract_reducer,
  add_diplomat_reducer,
  add_diplomat_type_reducer,
  add_documents_reducer,
  add_favorite_reducer,
  add_invoices_reducer,
  add_location_reducer,
  add_new_ind_reducer,
  add_reserve_reducer,
  add_review_reducer,
  add_work_experience_reducer,
  apply_reducer,
  check_verification_code_reducer,
  contact_us_email_reducer,
  delete_account_reducer,
  delete_applications_reducer,
  delete_assignement_alert_reducer,
  delete_assignement_reducer,
  delete_file_reducer,
  delete_invitations_freelancer_reducer,
  delete_invoice_files_reducer,
  delete_invoices_reducer,
  delete_location_reducer,
  delete_review_reducer,
  delete_timecard_file_reducer,
  delete_work_experience_reducer,
  get_additional_user_reducer,
  get_all_assignements_reducer,
  get_all_competencies_reducer,
  get_applications_reducer,
  get_assignement_alert_reducer,
  get_assignement_reducer,
  get_availability_reducer,
  get_client_orga_reducer,
  get_company_data_reducer,
  get_contract_reducer,
  get_diplomat_types_reducer,
  get_documents_reducer,
  get_favorite_reducer,
  get_geolocation_reducer,
  get_invited_freelancer_reducer,
  get_invited_reducer,
  get_invoices_reducer,
  get_lang_reducer,
  get_liked_reducer,
  get_locations_reducer,
  get_notifications_reducer,
  get_orga_reducer,
  get_personal_data_reducer,
  get_profile_ratings_reducer,
  get_reserve_reducer,
  get_review_reducer,
  get_timecard_reducer,
  get_user_competency_reducer,
  get_work_experience_reducer,
  invite_freelancer_reducer,
  invite_reducer,
  like_reducer,
  loginReducer,
  login_management_reducer,
  logout_reducer,
  password_reducer,
  remove_availability_reducer,
  remove_competency_reducer,
  remove_favorite_reducer,
  remove_like_reducer,
  remove_reserve_reducer,
  reset_password_email_reducer,
  reset_password_reducer,
  search_reducer,
  send_verification_code_reducer,
  set_additional_user_reducer,
  set_assignement_reducer,
  set_client_orga_reducer,
  set_company_data_reducer,
  set_lang_reducer,
  set_location_reducer,
  set_notifications_reducer,
  set_orga_reducer,
  set_personal_data_reducer,
  set_reserve_reducer,
  set_timecard_reducer,
  start_saving_locations_reducer,
  update_user_file_reducer,
} from "./state/Reducers/UserReducer";

const initialState = {
  user_info: JSON.parse(sessionStorage.getItem("user_info")) || { user: {} },
  add_documents: {
    response: [
      { message: "", status: false },
      { message: "", status: false },
      { message: "", status: false },
    ],
  },
  login_management: JSON.parse(sessionStorage.getItem("admin")) || {},
  get_personal: { user: {} },
};

const reducer = combineReducers({
  user_info: loginReducer,
  change_password: password_reducer,
  reset_password_email: reset_password_email_reducer,
  reset_password: reset_password_reducer,
  add_diplomat: add_diplomat_reducer,
  add_documents: add_documents_reducer,
  get_documents: get_documents_reducer,
  get_personal: get_personal_data_reducer,
  set_personal: set_personal_data_reducer,
  get_company_data: get_company_data_reducer,
  set_company_data: set_company_data_reducer,
  add_industry: add_new_ind_reducer,
  get_all_competencies: get_all_competencies_reducer,
  get_user_competency: get_user_competency_reducer,
  add_competency: add_competency_reducer,
  remove_competency: remove_competency_reducer,
  get_notifications: get_notifications_reducer,
  set_notifications: set_notifications_reducer,
  get_profile_ratings: get_profile_ratings_reducer,
  get_work_experience: get_work_experience_reducer,
  add_work_experience: add_work_experience_reducer,
  add_contract: add_contract_reducer,
  get_contract: get_contract_reducer,
  get_orga: get_orga_reducer,
  set_orga: set_orga_reducer,
  set_timecard: set_timecard_reducer,
  get_timecards: get_timecard_reducer,
  delete_timecard_file: delete_timecard_file_reducer,
  delete_work_experience: delete_work_experience_reducer,
  get_availability: get_availability_reducer,
  add_availability: add_availability_reducer,
  remove_availability: remove_availability_reducer,
  get_invited: get_invited_reducer,
  invite: invite_reducer,
  invite_freelancer: invite_freelancer_reducer,
  get_invited_freelancer: get_invited_freelancer_reducer,
  delete_invitations_freelancer: delete_invitations_freelancer_reducer,
  get_client_orga: get_client_orga_reducer,
  set_client_orga: set_client_orga_reducer,
  get_locations: get_locations_reducer,
  add_locations: add_location_reducer,
  set_locations: set_location_reducer,
  delete_locations: delete_location_reducer,
  start_saving_locations: start_saving_locations_reducer,
  get_additional_users: get_additional_user_reducer,
  set_additional_users: set_additional_user_reducer,
  add_additional_users: add_additional_user_reducer,
  add_assignement: add_assignement_reducer,
  add_review: add_review_reducer,
  delete_review: delete_review_reducer,
  get_reviews: get_review_reducer,
  get_assignement: get_assignement_reducer,
  get_geolocation: get_geolocation_reducer,
  get_all_assignements: get_all_assignements_reducer,
  set_assignement: set_assignement_reducer,
  delete_assignement: delete_assignement_reducer,
  update_user_files: update_user_file_reducer,
  research: search_reducer,
  like: like_reducer,
  get_liked: get_liked_reducer,
  remove_like: remove_like_reducer,
  apply: apply_reducer,
  get_applications: get_applications_reducer,
  delete_application: delete_applications_reducer,
  add_assignement_alert: add_assignement_alert_reducer,
  get_assignement_alert: get_assignement_alert_reducer,
  delete_assignement_alert: delete_assignement_alert_reducer,
  login_management: login_management_reducer,
  add_invoice: add_invoices_reducer,
  get_invoices: get_invoices_reducer,
  delete_invoice: delete_invoices_reducer,
  delete_invoice_files: delete_invoice_files_reducer,
  add_favorite: add_favorite_reducer,
  get_favorite: get_favorite_reducer,
  remove_favorite: remove_favorite_reducer,
  get_reserve: get_reserve_reducer,
  add_reserve: add_reserve_reducer,
  set_reserve: set_reserve_reducer,
  remove_reserve: remove_reserve_reducer,
  verify_email: send_verification_code_reducer,
  check_code: check_verification_code_reducer,
  delete_file: delete_file_reducer,
  get_diplomat_types: get_diplomat_types_reducer,
  contact_us_email: contact_us_email_reducer,
  set_lang: set_lang_reducer,
  get_lang: get_lang_reducer,
  add_diplomat_type: add_diplomat_type_reducer,
  logout: logout_reducer,
  delete_account: delete_account_reducer,
});

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  reducer,
  initialState,
  composeEnhancer(applyMiddleware(thunk))
);

export default store;
